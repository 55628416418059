[class="cancel-button"] {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 32px;
  height: 48px;
  width: 200px;
  border-radius: 2px;
  border: 2px solid #EE505E;
  color: #EE505E;
  background-color: white;
  margin-top: 48px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
}

