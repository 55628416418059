[class="heading"] {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  font-family: "acumin-pro", sans-serif;
  color: #000000;
}
[class="subheading"] {
  font-family: "acumin-pro", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.01em;
  font-style: normal;
  color: #000000;
  text-align: left;
}

[class="textInput"] {
  width: 100%;
  border-radius: 4px;
  padding: 8px 16px;
  background: #ffffff;
  margin-top: 8px;
  padding-right: 10px;
  box-sizing: border-box;
  height: 48px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  font-weight: 100;
  font-family: "acumin-pro", sans-serif;
}

[class="textInput"]:focus {
  outline: none;
}

[class="inputIcons"] {
  position: absolute;
  min-width: 50px;
  text-align: center;
  border: none;
  display: flex;
  top: 22px;
  right: 15px;
  margin: auto;
  padding: 0;
  outline: none;
  align-items: center;
}
[class="message"] {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  font-style: bold;
  padding-left: 4px;
  font-family: "acumin-pro", sans-serif;
  align-items: center;
  padding-bottom: 1px;
}

[class="email-form"] {
  position: relative;
  margin: "16px 16px 40px 16px";
}

[class="email-form-input"] {
  width: 538px;
  height: 48px;
  margin-top: 24px;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 16px;
  padding-left: 16px;
}

[class="email-form-input"]:focus {
  outline: none;
}

[class="email-form-input"]:placeholder-shown + button {
  opacity: 0;
  pointer-events: none;
}

[class="email-form-button"] {
  background: white;
  position: absolute;
  border: none;
  display: block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 18px;
  top: 21px;
  bottom: 0;
  right: 12px;
  margin: auto;
  padding: 0;
}

[class="email-form-button"]:disabled {
  background: #f7f7f7;
}
