[class="deliveryAddress"] {
  font-family: "acumin-pro", sans-serif;
  background-color: #fafafa;
  padding: 40px 24px;
  margin-top: 24px;
}
[class="deliveryAddress"] [class="heading"] {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  padding-bottom: 8px;
}

[class="deliveryAddress"] [class="subheading"] {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 16px;
}

[class="customerAddress"] {
  padding-bottom: 16px;
}

[class="customerAddress"] p {
  font-family: "acumin-pro", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
}

[class="editAddress"] {
  border: none;
  background-color: inherit;
  font-family: "acumin-pro", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  padding: 0;
  text-decoration: underline;
}

[class="addressForm"] {
  display: "flex";
  flex-direction: "column";
}

[class="addressForm"] input {
  height: 48px;
  left: 0px;
  top: 36px;
  border-radius: 4px;
  padding: 8px 16px 8px 16px;
  border: 1px solid #c4c4c4;
  width: 100%;
  font-family: "acumin-pro", sans-serif;
  font-size: 16px;
  font-weight: 200;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}

[class="customerName"] {
  display: flex;
}

[class="inputBlock"] {
  width: 100%;
}
[class="inputBlock"] p {
  font-family: "acumin-pro", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 12px;
  padding-top: 24px;
}
