[class="logoContainer"] {
  position: fixed;
  background-color: #1d1d1b;
  width: 100%;
  top: -16px;
  left: 0;
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
}
