[class="payment-ref-container"] {
  margin: 24px 0px;
}

[class="payment-ref-heading"] {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

[class="payment-ref-input"] {
  height: 48px;
  width: 140px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  margin-top: 12px;
  padding-left: 12px;
  font-size: 16px;
}
