[class="order-confirmation"] {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 78px;
  height: calc(100vh - 78px);
  gap: 53px;
}

[class="order-confirmation"] [class="text-container"] {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

[class="order-confirmation"] [class="text-container"] [class="order-status"] {
  font-family: "acumin-pro", sans-serif;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
}

[class="order-confirmation"] [class="text-container"] [class="order-subtext"] {
  width: 509px;
  font-family: "acumin-pro", sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  color: #777;
  text-align: center;
}

[class="order-number-heading"] {
  font-family: "acumin-pro", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-top: 24px;
}

[class="order-number"] {
  font-family: "acumin-pro", sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
}

[class="order-confirmation"] button {
  width: 509px;
  color: white;
  background-color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 52px;
  border-radius: 2px;
  border: none;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.01em;
}

/* find a better way to do this */
[class="order-confirmation"] [class="success-bg"] {
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 50%;
  width: 75.25px;
  height: 75.25px;
  background-color: #85b09a;
}

[class="order-confirmation"] [class="error-bg"] {
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 50%;
  width: 75.25px;
  height: 75.25px;
  background-color: #ee505e;
}
