[class="orderBtn"] {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 32px;
  height: 48px;
  border-radius: 2px;
  border: none;
  margin-top: 60px;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}