[class="error-banner-wrapper"] {
  background-color: #FAFAFA;
  border-left: 5px solid #C5003E;
  padding: 8px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

[class="error-banner-text"] {
  height: 32px;
  width: 32px;
  margin-right: 16px;
}

[class="close-error-banner"] {
  border: none;
  background-color: #FAFAFA;
  align-self: start;
  justify-self: flex-end;
}