[class="itemlist-heading"] {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  font-family: "acumin-pro", sans-serif;
  color: #000000;
}

[class="itemlist-subheading"] {
  font-family: "acumin-pro", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

[class="itemlist-body-small"] {
  font-family: "acumin-pro", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  font-style: normal;
  color: #000000;
  text-align: left;
}

[class="itemlist-heading-container"] {
  display: flex;
  align-items: center;
  gap: 8px;
}

[class="itemlist-empty-bag-container"] {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 12px;
  margin: 80px auto;
}

[class="itemlist-empty-bag-text-container"] {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

[class="itemlist-items-container"] {
  display: flex;
  flex-direction: column;
  margin: 24px auto;
}

[class="itemlist-details"] {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 32px auto;
}

[class="itemlist-row-space-between"] {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

[class="itemlist-flex-row"] {
  display: flex;
  flex-direction: row;
}

[class="flex-1"] {
  flex: 1;
}

[class="fullwidth"] {
  width: 100%;
}

[class="no-margin"] {
  margin: 0;
}

[class="itemlist-remove-button"] {
  color: black;
  background: none;
  border: none;
  line-height: 18px;
  font-size: 18px;
  outline: none;
  cursor: pointer;
  transition: 0.1s;
  margin-left: 12px;
}
