[class="totals-container"] {
  display: flex;
  flex-direction: column;
  margin: 180px auto 24px auto;
  gap: 24px;
}

[class="totals-delivery-banner"] {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ff8fa2;
  border-radius: 4px;
  margin-top: 12px;
  height: 32px;
  gap: 8px;
}

[class="totals-font-small-print"] {
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  font-family: "acumin-pro", sans-serif;
  letter-spacing: 0.01em;
}

[class="totals-vat"] {
  align-self: flex-end;
}
