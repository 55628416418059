[class="startOrderBtnStyles"] {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 32px;
  height: 52px;
  left: 769px;
  top: 824px;
  background: #1b1b1b;
  border-radius: 2px;
  margin-top: 40px;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}

[class="idleScreenContainerStyles"] {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 90%;
  width: 100%;
  margin-top: 0px !important;
}
