[class="legal-agreement-container"] {
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
}

[class="legal-agreement-checkbox"] {
  height: 42px;
  width: 42px;
}

[class="legal-agreement-checkbox"]:checked {
  filter: grayscale(1) contrast(5);
}
