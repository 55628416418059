[class="add-item-form"] {
  width: 100%;
  position: relative;
}

[class="add-item-input"] {
  width: 100%;
  margin: 24px 0px 32px 0px;
  box-sizing: border-box;
  padding-left: 16px;
  padding-left: 40px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  height: 48px;
  font-size: 16px;
  color: #000;
  outline: none;
}

[class="add-item-input"]:disabled {
  background-color: white;
}

[class="search-icon"] {
  position: absolute;
  min-width: 50px;
  text-align: center;
  border: none;
  display: flex;
  top: 32px;
  left: 12px;
  margin: auto;
  padding: 0;
  outline: none;
  align-items: center;
}

[class="addItemBtn"] {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 32px;
  height: 48px;
  border-radius: 2px;
  border: none;
  margin-top: 24px;
  width: 200px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}

[class="add-item-container"] {
  background: #fafafa;
  padding: 40px 24px;
  margin-top: 24px;
}

[class="add-item-heading"] {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

[class="add-item-quantities-container"] {
  margin-bottom: 24px;
}

[class="qty-container"] {
  display: inline-block;
  width: 140px;
  height: 90px;
  margin-right: 24px;
}

[class="qty-label"] {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

[class="qty-select"] {
  width: 140px;
  height: 48px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  margin-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  color: #000000;
  background-color: #fff;
  font-size: 16px;
}

[class="select-wrapper"] {
  position: relative;
  width: 140px;
  height: 48px;
}

[class="select-wrapper"]::after {
  content: "⌄";
  font-size: 18px;
  top: 22px;
  right: 16px;
  position: absolute;
  pointer-events: none;
}

[class="add-item"] {
  width: 538px;
  position: absolute;
  top: 70px;
  background-color: white;
  max-height: 230px;
  overflow-y: auto !important;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-items: center;
  border: 1px solid #c4c4c4;
  z-index: 99999;
}

[class="item-suggestion"] {
  margin: 8px 14px;
  height: 40px;
  font-size: 16px;
  font-family: "acumin-pro", sans-serif;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.01em;
  display: flex;
  align-items: center;
}

[class="add-item-remove-button"] {
  position: absolute;
  top: 38px;
  right: 8px;
  border: none;
  background: transparent;
  line-height: 18px;
  font-size: 18px;
}
